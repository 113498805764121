module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Feel at home","short_name":"ownahome.ng","start_url":"/","background_color":"#ffffff","theme_color":"#5cb85c","display":"minimal-ui","icon":"content/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"118f8dbb066b8914351fec3a2abbb055"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Land, Houses, Apartments for Sale in Lagos, Abuja Nigeria and Dubai for Sale","titleTemplate":"%s | Ownahome.ng | Feel at Home ","description":"Find the best property for sale in Lagos, Abuja Nigeria and Dubai. Vetted and tested properties by the best developers and Real Estate Companies","defaultOpenGraphImageHeight":1200,"defaultOpenGraphImageWidth":1200,"mobileAlternate":{"media":"only screen and (max-width: 640px)","href":"https://ownahome.ng"},"languageAlternates":[{"hrefLang":"en","href":"https://www.ownahome.ng"}],"openGraph":{"type":"website","locale":"en_US","url":"https://www.ownahome.ng","title":"Land, Houses, Apartments for Sale in Lagos, Abuja Nigeria and Dubai for Sale","description":"Find the best property for sale in Lagos, Abuja Nigeria and Dubai. Vetted and tested properties by the best developers and Real Estate Companies","images":[{"url":"https://images.ctfassets.net/abyiu1tn7a0f/3uayWgAJtC18tB0QIb7vKC/353cf6f692a85e7a54d8f0eb84e8d978/2021-04-09_17-22-23_UTC_10.jpg?h=250","width":800,"height":600,"alt":"house for sale in ikoyi"}],"site_name":"Feeling at home"},"twitter":{"handle":"@ownahome_ng","site":"@owanhome_ng","cardType":"summary_large_image"},"facebook":{"appId":"728383407955546"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WQXPFBS","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.ownahome.ng"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-86654560-3","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1553737644829134"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"68554d96-f7a0-4b4e-84cc-0e2ac8134ab6","enableDuringDevelop":false,"defer":true,"enableImprovedAccessibility":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1140,"showCaptions":true,"linkImagesToOriginal":false,"disableBgImageOnAlpha":true}},{"resolve":"gatsby-remark-embed-video","options":{"width":800}},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"}],"remarkPlugins":[null],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/site"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../packages/blog/gatsby-blog-core/gatsby-browser.js'),
      options: {"plugins":[],"paginatePostsPage":true,"homePostsPerPage":9,"siteUrl":"https://ownahome.ng","basePath":"/blog","sources":{"contentful":true,"local":true},"services":{"mailchimp":true,"disqus":true}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Inter:400,600,700,900&display=swap"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
