import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@popperjs/core/dist/umd/popper.min.js'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import './content/assets/css/style.css'
import './content/assets/css/responsive.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { GlobalWrapper } from './src/context/globalContext'

export const wrapRootElement = ({ element }) => (
  <GlobalWrapper>{element}</GlobalWrapper>
)
