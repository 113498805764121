import React, { useState, createContext, useContext } from 'react'
import { navigate } from 'gatsby'

const initState = {
  type: '',
  category: 'for-sale',
  location: '',
  bedroom: '',
  maxPrice: '',
  minPrice: '',
  singleSelections: []
}

const defaultState = {
  initValue: initState,
  getProperty: e => {},
  handleDirect: () => {},
  newProp: []
}

export const GlobalContext = createContext(defaultState)

export const useGlobalContext = () => useContext(GlobalContext)

export const GlobalWrapper = ({ children }) => {
  const [newProp, setNewProp] = useState([])
  const [initValue, setInitValue] = useState(initState)

  const getProperty = (datas = []) => {
    const filteredData = datas.filter(data => {
      let matches = true
      let containsQuery = false

      const isBedroom = Number(data.bedroom) == Number(initValue.bedroom)
      const isPriceRange =
        Number(data.newerprice) > Number(initValue.minPrice) ||
        Number(data.newerprice) < Number(initValue.maxPrice)

      if (initValue.bedroom || initValue.maxPrice || initValue.minPrice) {
        // OR operand search
        if (initValue.bedroom) {
          if (isBedroom) {
            containsQuery = true
          }
        }

        if (initValue.maxPrice || initValue.minPrice) {
          if (isPriceRange) {
            containsQuery = true
          }
        }

        if (initValue.bedroom && (initValue.maxPrice || initValue.minPrice)) {
          if (isBedroom && isPriceRange) {
            containsQuery = true
          } else {
            containsQuery = false
          }
        }

        // AND operand search
        if (initValue.bedroom && (initValue.maxPrice || initValue.minPrice)) {
          if (isBedroom && isPriceRange) {
            containsQuery = true
          } else {
            containsQuery = false
          }
        }

        if (!containsQuery) {
          matches = false
        }
      }

      return matches
    })

    setNewProp(filteredData)
  }

  const handleDirect = () => {
    if (initValue.type !== '') {
      navigate(
        `/property/${initValue.category}/${initValue.type}/${initValue.location}`
      )
    } else {
      navigate(`/property/${initValue.category}/${initValue.location}`)
    }
  }

  return (
    <GlobalContext.Provider
      value={{ initValue, setInitValue, getProperty, handleDirect, newProp }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
